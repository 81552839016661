<template>
  <div class="content">
    <moreHeader></moreHeader>
    <div class="header">
      <h1>智慧交通</h1>
    </div>
    <div class="zhihujiaot">
      <div
        class="listbtn"
        v-for="(item, i) in navbar"
        :key="i"
        :class="{ porent: item.id == creen }"
        @click="onclick(item.id)"
      >
        {{ item.name }}
      </div>
    </div>
    <div class="hihujt">
      <img :src="navbar[creen].img" alt="" />
      <div class="navdice">
        <p>{{ navbar[creen].dice }}</p>
      </div>
    </div>
    <div class="framework">
      <h2 class="title">平台架构</h2>

      <img
        @click="onYuLan(navbar[creen].imgjg)"
        :src="navbar[creen].imgjg"
        alt=""
      />
    </div>
    <div class="changjinggongn" v-show="creen == 0">
      <div class="gongnentit">功能场景</div>
      <div class="changjingitem" v-for="(item, i) in scenelist" :key="i">
        <img :src="item.img" alt="" />
        <div class="changjtit">{{ item.title }}</div>
        <div class="changjdeac">{{ item.deas }}</div>
      </div>
    </div>
    <div class="changjinggongntwo" v-show="creen == 1">
      <div class="gongnentit">场景目标</div>
      <div class="changjingitem" v-for="(item, i) in scenelista" :key="i">
        <img :src="item.img" alt="" />
        <div class="changjtit">{{ item.title }}</div>
        <div class="changjdeac">{{ item.deas }}</div>
      </div>
    </div>
    <div class="changjinggongnthree" v-show="creen == 2">
      <div class="gongnentit">功能场景</div>
      <div class="box" v-for="(item, i) in carlist" :key="i">
        <div class="pinktit"><img :src="item.img" alt="" />{{ item.name }}</div>
        <div class="pinkdaes">{{ item.dice }}</div>
      </div>
    </div>
    <div class="changjinggongnfor" v-show="creen == 3">
      <div class="gongnentit">场景目标</div>
      <div class="centoin">
        <img src="../../assets/assets/trafficImg/bg2.png" alt="" />
        <div class="contidk">
          <div class="creen4left">
            <div class="creen4leftlist">
              <span class="lengxing"></span
              ><span class="lengtit">提升城市公共交通运行监测水平</span>
            </div>
            <div class="creen4leftlist">
              <span class="lengxing"></span
              ><span class="lengtit">提升城市公共交通企业智能化调度水平</span>
            </div>
            <div class="creen4leftlist">
              <span class="lengxing"></span
              ><span class="lengtit">提升出行信息服务水平</span>
            </div>
            <div class="creen4leftlist">
              <span class="lengxing"></span
              ><span class="lengtit">提升行业监管水平</span>
            </div>
            <div class="creen4leftlist">
              <span class="lengxing"></span
              ><span class="lengtit">提升安全保障水平</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moreHeader from "../../components/header";
import { ImagePreview } from "vant";
export default {
  components: { moreHeader },
  name: "",
  data() {
    return {
      creen: 0,
      active: 0,
      active1: 0,
      navbar: [
        {
          id: 0,
          name: "智慧公路综合管理",
          img: require("../../assets/assets/trafficImg/img1.png"),
          imgjg: require("../../assets/assets/trafficImg/jg1.png"),
          dice: "基于对公路、高架等三维地理信息的采集与建模，进行交通公路资产、设施设备数字化管理，并进行交通数字孪生，实现在基于孪生的交通仿真、事件预警处置、信息服务于管理决策功能。",
        },
        {
          id: 1,
          name: "集团数字化建设项目管理",
          img: require("../../assets/assets/trafficImg/img2.png"),
          imgjg: require("../../assets/assets/trafficImg/jg2.png"),
          dice: "全方位提高集团整体的项目管理水平，增强企业对施工安全、质量、现场、材料等方面的管理力度。通过实时管理、远程管理、智能管理等精细化管理手段，将项目现场管理与企业管理融合在一起，实现现场施工管理模式的创新。",
        },
        {
          id: 2,
          name: "智慧出行管理服务",
          img: require("../../assets/assets/trafficImg/img3.png"),
          imgjg: require("../../assets/assets/trafficImg/jg3.png"),
          dice: "改进乘客出行信息服务方式，构建内容丰富、形式多样、及时可靠的出行信息服务体系，提高公共交通系统的出行信息服务能力。提升公交运行主动安全防范能力，基于车载设备与公安等部门共享数据，减少安全隐患。",
        },
        {
          id: 3,
          name: "标准化场站安全管控",
          img: require("../../assets/assets/trafficImg/img4.png"),
          imgjg: require("../../assets/assets/trafficImg/jg4.png"),
          dice: "以战场管理“看得见、听得到、调的动”为目标，实现战场管理业务流程串联，提升战场管理水平和服务水平；规范战场终端和数据接入规则，为后续全面规范化、标准化战场建设奠定坚实基础。",
        },
      ],
      list: [
        {
          id: 0,
          name: "智慧农业",
          img: require("../../assets/assets/ruralImg/img2.png"),
          text: "通过将物联网、大数据、云计算等技术应用到传统农业中，实现了对农业农田项目全周期的可追溯管理，通过农业远程诊断、远程控制等智能管理，极大节省了人力成本，提高了农田管理效率。",
        },
        {
          id: 1,
          name: "智慧党建",
          img: require("../../assets/assets/ruralImg/img3.png"),
          text: "以云计算、大数据、互联网为基础，建设全覆盖、全过程、全维度的党建信息化、智慧化的平台， 实现线上线下相互融合、相互促进的党建工作新格局。",
        },
        {
          id: 2,
          name: "智慧村务",
          img: require("../../assets/assets/ruralImg/img4.png"),
          text: "智慧村务旨在打破条块分割局面，整合民政、计生、人社等延伸到农村的政务系统和其他各类农村信息资源，实现农村信息资源共享。",
        },
        {
          id: 3,
          name: "精准扶贫",
          img: require("../../assets/assets/ruralImg/img5.png"),
          text: "针对不同贫困区域环境、不同贫困农户状况，运用科学有效程序对扶贫对象实施精确识别、精准帮扶、精确管理的治贫方式。",
        },
      ],
      list1: [
        {
          id: 0,
          name: "便民服务",
          img: require("../../assets/assets/ruralImg/img6.png"),
          text: "村民可在手机上进行村情上报、村民招聘就业、家宴预约、乡村咨询等，让村民办事更方便。",
        },
        {
          id: 1,
          name: "智慧文旅",
          img: require("../../assets/assets/ruralImg/img7.png"),
          text: "利用云计算、物联网、移动通信等多种先进技术，构建景区智慧文旅体系，提高文旅企业的综合管理和运营能力，创建优质的旅游生态环境，提升旅游的服务品质。",
        },
        {
          id: 2,
          name: "信息发布",
          img: require("../../assets/assets/ruralImg/img8.png"),
          text: "采用LED大屏，可以实施实时查看各类农业信息、村情上报、道路状况等信息。",
        },
        {
          id: 3,
          name: "监督监管",
          img: require("../../assets/assets/ruralImg/img9.png"),
          text: "履责有载体、监督有平台、建言有渠道、宣传有引导，让党内“一线”监督在基层落地生根。",
        },
      ],
      scenelist: [
        {
          img: require("../../assets/assets/trafficImg/jiaot1.png"),
          title: "交通数字孪生",
          deas: "利用交通工程模型、交通传感器、历史和实时数据，集交通工程学、人工智能、大数据等多学科、多尺度、多维度、多概率的数字交通还原和仿真过程，以全息视觉交通感知传感器技术和数据为基础，融合GPS、微波雷达、地感、CO/VI、气象等多传感器数据构建的全息交通数字孪生系统。",
        },
        {
          img: require("../../assets/assets/trafficImg/jiaot7.png"),
          title: "突发事件管理",
          deas: "基于多种前端视频监控、感知设备实现全域数字化道路事件实时感知，可对事件准确性进行人工校核，在根据预案对事件进行处置后，可对相应事件的处置状态进行人工标定",
        },
        {
          img: require("../../assets/assets/trafficImg/jiaot2.png"),
          title: "事件预警管理",
          deas: "搭建预警算法模型，构建预警规则，根据视频监控、传感器回传的数据进行解析和分析，触发预警规则的数据，形成告警事件，实现在线事件预警",
        },
        {
          img: require("../../assets/assets/trafficImg/jiaot3.png"),
          title: "事件协同监管",
          deas: "通过预警规则生成的事件列表，创建事件工单，提交事件，进入流转环节，牵头部门、协同部门，对事件进行处置。完成后对事件工单分类统计，并提取出热度分类，以及处置效率。",
        },
        {
          img: require("../../assets/assets/trafficImg/jiaot4.png"),
          title: "交通信息发布",
          deas: "结合公路上诱导屏、语音广播、护栏防撞诱导等硬件设备及移动端，对交通拥堵、施工信息、封道限流、车辆异常行为、安全事件实时预警、设备告警信息发布",
        },
        {
          img: require("../../assets/assets/trafficImg/jiaot5.png"),
          title: "事件上报",
          deas: "司乘人员发现交通事件、公路安全隐患等，拍照一键上报，实现社会公众参与公共交通管理中，查询事件处置处理进度、处理意见和状态，价对上报事件处置处理结果进行评价。对指挥中心的处置处理效率、结果，进行有效的监管。",
        },
        {
          img: require("../../assets/assets/trafficImg/jiaot6.png"),
          title: "交通信息查询",
          deas: "公众人员出行前、出行过程中可查询公路实时路况信息，包括道路拥堵等情况，对公路事故情况实时提醒，实时更新事故处理情况，以及气温、能见度、湿度等信息等气象信息，为司乘人员出行提供有效的信息支撑。",
        },
      ],
      scenelista: [
        {
          img: require("../../assets/assets/trafficImg/num1.png"),
          title: "数据横向打通，提高工作效率",
          deas: "通过管理系统的应用不仅完成了项目部内部组织体系、施工计划、资金流向以及流程审批等多种数据的打通，同时联通了项目部与公司、业主和相关方的各个协作方，贯通了施工的上下游管理链条，实现了数据的统一管理和统筹分析，有效地提高了项目管理能力和系统能力。",
        },
        {
          img: require("../../assets/assets/trafficImg/num2.png"),
          title: "物联数据互通，打破数据孤岛",
          deas: "将原本分散在多个厂家、多个应用、多个系统的物联数据汇聚到统一的智慧工程平台中，打破了智慧工程应用碎片化的现状，有效地消除数据孤岛，对于项目智慧工程设备的统一监管、预警信息的集中管控提供工具，提升应对响应能力",
        },
        {
          img: require("../../assets/assets/trafficImg/num3.png"),
          title: "建立相关标准，形成预警体系",
          deas: "通过智能建造系统平台的建立，形成企业自身的智能建造相关技术、数据标准/导则，生成项目日常生产建设中的各项预警信息，为项目日常管理提供依据和警示，做到提前预警防范未然。",
        },
        {
          img: require("../../assets/assets/trafficImg/num4.png"),
          title: "建立数字化平台，构筑竞争新优势",
          deas: "智能建造数字化平台，通过物联网设备和互联网技术，依托云底座，提高了集团公司数字化、智慧建造意识，培育基于数据驱动的企业新型能力，构筑公司竞争新优势。",
        },
        {
          img: require("../../assets/assets/trafficImg/num5.png"),
          title: "完善项目监管体系，形成标准化业务流程",
          deas: "通过数字平台应用，利用数字化技术和手段，将项目施工的全流程数据串联，统一集团的建设项目安全质量监管体系，形成标准化建设业务流程。",
        },
      ],
      carlist: [
        {
          name: "改进",
          dice: "改进行业运行监管方式，完善公交运行状态与数据采集体系，提升行业及企业智能调度与运营管理效率，提高城市公共交通行业动态监测、指挥协调、服务监管、异常预警和辅助决策效能；",
          img: require("../../assets/assets/trafficImg/icon1.png"),
        },
        {
          name: "服务",
          dice: "改进乘客出行信息服务方式，构建内容丰富、形式多样、及时可靠的出行信息服务体系，提高公共交通系统的出行信息服务能力。",
          img: require("../../assets/assets/trafficImg/icon2.png"),
        },
        {
          name: "防范",
          dice: "提升公交运行主动安全防范能力，基于车载智能终端与公安等部门共享数据，消除安全隐患。",
          img: require("../../assets/assets/trafficImg/icon3.png"),
        },
      ],
    };
  },

  methods: {
    go(id) {
      this.$router.push({
        path: "/cityDetail",
        query: {
          cityId: id,
        },
      });
    },
    onclick(id) {
      this.creen = id;
    },
    onYuLan(img) {
      console.log(img);
      ImagePreview([img]);
    },
  },
};
</script>

<style scoped lang="less">
.content {
  box-sizing: border-box;
  margin-top: -27px;
  .title {
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: 600;
    color: #333333;
    text-align: center;
    padding: 20px 0;
  }
  .header {
    height: 480px;
    background: url("../../assets/assets/traffic.png") no-repeat;
    background-size: 100% 100%;
    padding-left: 10px;
    > h1 {
      font-size: 20px;
      font-weight: normal;
      color: #fff;
      padding-top: 100px;
      margin: 0;
    }
    > p {
      padding: 20px 10px 0 0px;
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 300;
      line-height: 30px;
      color: #ffffff;
    }
  }
  .framework {
    img {
      width: calc(100% - 20px);
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-left: 10px;
    }
    .img3 {
      width: calc(100% - 20px);
      height: 1080px;
      background: url("../../assets/assets/ruralImg/img1.png") no-repeat;
      background-size: contain;
      margin: 0px 0 0 10px;
    }
  }
}
::v-deep .van-tabs__line {
  background-color: #365ef1;
}
.zhihujiaot {
  padding: 10px 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  .listbtn {
    border: 1px solid #cccccc;
    border-radius: 2px;
    width: calc(50% - 5px);
    height: 34px;
    font-size: 14px;
    text-align: center;
    line-height: 34px;

    font-weight: normal;
    margin-top: 10px;

    color: #333333;
  }
  .porent {
    color: #365ef1;
    border: 1px solid #5274f2;
  }
}
.hihujt {
  // height: 310px;
  // width: 100%;
  margin: 0 10px;
  background: #fff;
  img {
    // height: 200px;
    width: 100%;
  }
  .navdice {
    padding: 15px 10px;
    line-break: anywhere;
    p {
      margin: 0;
      font-size: 14px;
      color: #6b6b6b;
      line-break: anywhere;
    }
  }
}
.changjinggongn {
  margin-top: 10px;
  background: #fff;
  .gongnentit {
    height: 68px;
    line-height: 68px;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    color: #333333;
  }
  .changjingitem {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 10px;
    img {
      width: 80px;
      height: 80px;
    }
    .changjtit {
      font-size: 18px;
      line-height: 40px;

      font-weight: 400;

      color: #333333;
    }
    .changjdeac {
      padding: 0 10px;
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #6b6b6b;
      line-break: anywhere;
    }
  }
  .changjingitem:last-child {
    padding-bottom: 40px;
  }
}
.changjinggongntwo {
  margin-top: 20px;

  .gongnentit {
    height: 40px;
    // line-height: 80px;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    color: #333333;
    display: flex;
    // align-items: center;
    justify-content: center;
  }
  .changjingitem {
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 10px;
    padding-top: 20px;
    img {
      width: 140px;
    }
    .changjtit {
      font-size: 16px;
      line-height: 40px;

      font-weight: 400;

      color: #333333;
    }
    .changjdeac {
      padding: 0 10px;
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #6b6b6b;
    }
  }

  .changjingitem:last-child {
    padding-bottom: 20px;
  }
}
.changjinggongnthree {
  // margin-top: 20px;

  .gongnentit {
    height: 56px;
    line-height: 56px;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    color: #333333;
    // display: flex;
    // align-items: center;
    // justify-content: center;
  }
  .box {
    background: url("../../assets/assets/trafficImg/bg.png") no-repeat;
    background-size: 100% 100%;
    margin: 0 10px;
    height: 166px;
    font-size: 12px;
    margin-bottom: 10px;
    .pinktit {
      width: 100px;
      height: 36px;
      background: #c7b090;
      opacity: 1;
      border-radius: 0px 0px 10px 0px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      color: #fff;
      font-weight: 600;
      img {
        width: 16px;
        // height: 36px;
        margin-right: 10px;
      }
    }
    .pinkdaes {
      padding: 0 10px;
      margin-top: 10px;
      font-size: 16px;

      font-weight: 400;

      color: #6b6b6b;
      opacity: 1;
    }
  }
}
.changjinggongnfor {
  // margin-top: 20px;

  .gongnentit {
    height: 56px;
    line-height: 56px;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    color: #333333;
  }
  .centoin {
    padding: 0 10px;
    display: flex;
    flex-direction: column;
    img {
      width: 100%;
    }
    .contidk {
      width: 100%;
      height: 200px;
      background: #fff;
      .creen4left {
        width: 100%;
        background-color: #ffffff;
        font-size: 16px;
        .creen4leftlist {
          display: flex;
          flex-direction: row;
          align-items: center;
          height: 26px;
          color: #6b6b6b;
          .lengxing {
            display: inline-block;
            width: 10px;
            height: 10px;
            background-color: #e9e9e9;
            transform: rotate(45deg);
            margin: 20px;
          }
          .lengtit {
            color: #6b6b6b;
          }
        }
        .creen4leftlist:nth-child(1) {
          margin-top: 30px;
        }
      }
    }
  }
}
</style>
